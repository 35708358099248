import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex';
import App from './App.vue'
import createRouter from './router/index'
import creatStore from './store/index'
import ElementUI from 'element-ui'
//样式需要单独引入
import 'element-ui/lib/theme-chalk/index.css'
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 当前的域名是否为互动峰
function isHuDongFeng() {
  const origin = window.location.origin
  return origin.includes('haodaifu.cn') || origin.includes('10.3.203.38');
}

if (isHuDongFeng()) {
  document.title = '互动峰科技（北京）有限公司'
}

// 在 Vue 实例的全局属性 $HUDONGFENG 上挂载当前域名
Vue.prototype.$HUDONGFENG = isHuDongFeng();
//挂载
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(ElementUI);
const router = createRouter();
const store = creatStore();
Vue.config.productionTip = false;
Vue.config.devtools = true;
//全局路由的钩子
router.beforeEach((to,from,next)=>{
  next();
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
