<template>
    <header>
        <div class="logo-area">
            <!-- <img class="logo" src="https://i2.hdfimg.com/ssi/image/logo/logo_icon.png" alt="logo"> -->
            <div class="dec" v-if="!$HUDONGFENG">好大夫互联网医院平台</div>
            <!-- <ul class="b-menu">
                <li class='m-item'>
                    <router-link class="menu-link" to="/mycenter">
                    <em class="m-item-i ml15" style="background-position: -50px 0"></em><br><span class="m-item-t">个人中心</span>
                    </router-link>
                    </li>
                <li class='m-item'>
                       <router-link  class="menu-link" to="/">
                    <em class="m-item-i ml15" style="background-position: -25px "></em><br><span class="m-item-t">回到首页</span>      </router-link>
                </li>
                  <li class='m-item'>
                       <router-link  class="menu-link" to="/introduction">
                    <em class="m-item-i ml15" style="background-position: 0 "></em><br><span class="m-item-t">公司介绍</span>      </router-link>
                </li>
            </ul> -->
        </div>
    </header>
</template>
<script>
export default {
    
}
</script>
<style scoped>
    ul li{list-style-type:none;}
    .logo-area{
        position: relative;
        padding:30px 10px;
        line-height: 0;
    }
    .logo{
        position:absolute;
        left: 30px;
        top:20px;
        width: 60px;
        height: 60px;
    }
    .menu-link{display: inline-block;}
    .dec{display: inline-block;line-height: 1;color:rgb(11, 65, 200);font-weight:900;font-size: 40px;font-family: Brush Script MT, Brush Script Std, cursive;}
    .sub-dec{display: inline-block;font-size: 14px;line-height: 1;color:#999}
    .b-menu{position: absolute;top: 30px;right: 10px;}
    .m-item{float: right;line-height: 1;color:#f80;font-size: 12px;padding-right: 10px;}
    .m-item-i{display: inline-block;background-image: url(//i1.hdfimg.com/passport/images/top_menu_ico.png);width: 18px;height: 18px;}
    .ml15{margin-left:15px}
    .ml4{margin-left:4px}
    
    .m-item-t{display: inline-block;padding-top:3px; color:#f80;}
    /* ::marker{} */
</style>