import Router from 'vue-router';
const title = "新医强国";

// const index = ()=> import('../components/index.vue');
const order = () => import('../components/myorder.vue')
const center = ()=> import('../components/mycenter.vue')
const login = ()=> import('../components/login.vue');
const drugsort = () => import('../components/drugfenlei.vue');
const druglist = () => import('../components/druglist.vue');
// const drugdetail = () => import('../components/drugdetail.vue')
const drugdetail2 = () => import('../components/drugdetail2.vue')
const introduction = ()=>import('../components/introduction.vue')
const artlist = () => import('../components/artList.vue')
const art = () => import('../components/art.vue')
const pay = () => import('../components/pay.vue')
const zizhi = () => import('../components/zizhi.vue')
const zhishi = () => import('../components/zhishi.vue')
const dayidetail = ()=>import('../components/dayidetail.vue')
const dayi = ()=>import('../components/dayi.vue')
const tel = ()=>import("../components/tel.vue");
const payment = ()=>import("../components/payment.vue");

// warning babel-preset-env to resolve error for import

export default function createRouter() {
    const router = new Router({
        mode: "history",
        routes: [
            {
                path: '/',
                component: drugsort,
                name: 'drugsort'
            },
            {
                path: '/dayi',
                component: dayi,
                name: 'dayi',
                meta: {title}
            },
            {
                path: '/zhishi',
                component: zhishi,
                name: 'zhishi',
                meta: {title}
            },
            {
                path:'/introduction',
                component:introduction,
                name:'introduction',
                meta: {title}
            },
            {
                path: '/mycenter',
                component: center,
                name: 'mycenter',
                meta: {title},
                beforeEnter:(to,from,next)=>{
                     // TODO 登录校验
                    //  console.log(to);
                    //  console.log(from);
                    const userName = sessionStorage.getItem('userName');
                    if(!userName){
                        next({path: '/login'});
                        return;
                    }
                    next();
                }
            },
            {
                path: '/myorder',
                component: order,
                name: 'myorder',
                meta: {title}
            },
            {
                path: '/login',
                component: login,
                name: 'login',
                meta: {title}
            },{
                path:'/drugsort',
                component:drugsort,
                name:'drugsort',
                meta: {title}
            },
            {
                path:'/druglist',
                props: (route) => ({ cId: route.query.cId }) ,
                component:druglist,
                name:'druglist',
                meta: {title}
            },{
                path:'/drugdetail',
                props: (route) => ({ id: route.query.id }) ,
                component:drugdetail2,
                name:'drugdetail',
                meta: {title}
            },{
                path:'/artlist',
                props: (route) => ({ id: route.query.cId }) ,
                component:artlist,
                name:'artlist',
                meta: {title}
            },{
                path:'/art',
                props: (route) => ({ id: route.query.cId }) ,
                component:art,
                name:'art',
                meta: {title}
            },
            {
                path:'/pay',
                props: (route) => ({ id: route.query.cId }) ,
                component:pay,
                name:'pay',
                meta: {title}
            },
            {
                path:'/dayidetail',
                props: (route) => ({ id: route.query.Id }) ,
                component:dayidetail,
                name:'dayidetail',
                meta: {title}
            },
            {
                path:'/zizhi',
                component:zizhi,
                name:'zizhi',
                meta: {title}
            },{
                path:'/tel',
                component:tel,
                name:'tel',
                meta: {title}
            },{
                path:'/payment',
                component:payment,
                name:'payment',
                meta: {title}
            }

        ]
    })
    router.afterEach((to, from) => {
        console.log(to)
        if(to.name == "dayi"){
            console.log(from)
        }
        console.log(from)
    })
    return router;
}
