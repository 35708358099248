import Vuex from 'vuex'
const getters = {
    isLogin(state){return !!state.username },
    username(state){return state.username},
    getNavSelector(state){return state.navSelector}
}
const mutations = {
    setUserName(state,val){
        console.log('mutation.setUserName',val);
        state.username = val ;
        // console.log(this.username)
    },
    setNavSelector(state,val){
        state.navSelector = val ;
    }
}

export default () => {
    return new Vuex.Store({
        state:{
            username:'',// 用户名
            isLogin:false, // 登录状态
            orderList:[] ,// 订单列表
            navSelector:'index'
        },
        mutations,
        getters
       })
}
